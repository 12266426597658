exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviso-privacidad-tsx": () => import("./../../../src/pages/aviso-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-privacidad-tsx" */),
  "component---src-pages-blog-post-tsx": () => import("./../../../src/pages/blog/post.tsx" /* webpackChunkName: "component---src-pages-blog-post-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-casas-tsx": () => import("./../../../src/pages/casas.tsx" /* webpackChunkName: "component---src-pages-casas-tsx" */),
  "component---src-pages-contactanos-tsx": () => import("./../../../src/pages/contactanos.tsx" /* webpackChunkName: "component---src-pages-contactanos-tsx" */),
  "component---src-pages-cuotas-mantenimiento-plusvalia-tsx": () => import("./../../../src/pages/cuotas-mantenimiento-plusvalia.tsx" /* webpackChunkName: "component---src-pages-cuotas-mantenimiento-plusvalia-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infomerciales-tsx": () => import("./../../../src/pages/infomerciales.tsx" /* webpackChunkName: "component---src-pages-infomerciales-tsx" */),
  "component---src-pages-lotes-tsx": () => import("./../../../src/pages/lotes.tsx" /* webpackChunkName: "component---src-pages-lotes-tsx" */),
  "component---src-pages-politicas-cookies-tsx": () => import("./../../../src/pages/politicas-cookies.tsx" /* webpackChunkName: "component---src-pages-politicas-cookies-tsx" */),
  "component---src-pages-precios-tsx": () => import("./../../../src/pages/precios.tsx" /* webpackChunkName: "component---src-pages-precios-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-pages-terminos-condiciones-tsx": () => import("./../../../src/pages/terminos-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-condiciones-tsx" */),
  "component---src-pages-testimonios-tsx": () => import("./../../../src/pages/testimonios.tsx" /* webpackChunkName: "component---src-pages-testimonios-tsx" */)
}

